import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem("language") || "DE");

  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
    localStorage.setItem("language", language);
  }, [language, i18n]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

import React from "react";

import OnboardingLayouts from "../../components/layouts/OnboardingLayouts";
import OnboardingLeftContent from "./OnboardingLeftContent";
import OnboardingStepRenderer from "./OnboardingStepRenderer";
import { useStep } from "../../context/onboardingStepContext";

export default function Onboarding() {
  const { step } = useStep();

  return (
    <OnboardingLayouts>
      <div
        className={`grid grid-cols-1 ${
          (step === 5 || step === 6) ? "" : "md:grid-cols-2 mb-20"
        } gap-24 md:gap-0 md:mb-40`}
      >
        {step !== 5 ? <OnboardingLeftContent /> : null}
        <OnboardingStepRenderer />
      </div>
    </OnboardingLayouts>
  );
}

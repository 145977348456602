import { useEffect, useRef } from 'react';
import reactUuid from 'react-uuid';
import Cookies from 'js-cookie';
import axios from 'axios';

const useUserTracking = () => {
  const userIdentifierRef = useRef(Cookies.get('userIdentifier'));

  useEffect(() => {
    if (!userIdentifierRef.current || userIdentifierRef.current === 'undefined' || userIdentifierRef.current === undefined) {
      const userIdentifier = reactUuid();
      Cookies.set('userIdentifier', userIdentifier, { expires: 7 });
      userIdentifierRef.current = userIdentifier;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'gclid'].reduce((acc, param) => {
      const value = urlParams.get(param);
      if (value) acc[param] = value;
      return acc;
    }, {});

    axios.post('/api/v1/notifications', {
      message: `User \`${userIdentifierRef.current}\` landed on \`${window.location.pathname}\`.`,
      info: utmParams
    });
  }, []);
};

export default useUserTracking;

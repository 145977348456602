import React from "react";

export default function Label({ labelText, className, ...rest }) {
  return (
    <span
      className={`px-[15px] w-fit h-8 text-sm leading-4 font-medium text-primary-100 bg-primary-100 bg-opacity-5 rounded-[30px] flex items-center justify-center ${className}`}
      {...rest}
    >
      {labelText}
    </span>
  );
}

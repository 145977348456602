import React, { useState } from "react";

import Label from "../../components/ui/label";

import AvatarReview1 from "../../assets/images/avatar_review1.png";
import AvatarReview2 from "../../assets/images/avatar_review2.png";
import NextIcon from "../../assets/images/icon_next.svg";
import PrevIcon from "../../assets/images/icon_prev.svg";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

export default function Testimonials() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const NextArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div className={` ${className}`} onClick={onClick}>
        <img src={NextIcon} alt="" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div className={` ${className}`} onClick={onClick}>
        <img src={PrevIcon} alt="" />
      </div>
    );
  };

  const reviews = [
    {
      avatar: AvatarReview1,
      heading: t("reviews.0.heading"),
      quote: t("reviews.0.quote"),
      name: "Marko Jakic",
      role: "HVAC Technician",
    },
    {
      avatar: AvatarReview2,
      heading: t("reviews.1.heading"),
      quote: t("reviews.1.quote"),
      name: "Elias Kettler",
      role: "Electrician",
    },
    {
      avatar: AvatarReview1,
      heading: t("reviews.2.heading"),
      quote: t("reviews.2.quote"),
      name: "Anna Lena",
      role: "Technician",
    },
    {
      avatar: AvatarReview2,
      heading: t("reviews.3.heading"),
      quote: t("reviews.3.quote"),
      name: "Michael Hammer",
      role: "Electrician",
    },
  ];
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container testimonials mt-8 md:mt-16 xl:mt-24 pt-20 relative">
      <Label labelText={t("testimonials")} className={"mx-auto"} />
      <h4 className="text-heading4 md:text-heading3 text-black mt-10 text-center">
        {t("trusted_by")} <br /> {t("many_customers")}
      </h4>

      <div className="mt-16">
        <Slider
          {...settings}
          afterChange={(newIndex) => setActiveIndex(newIndex)}
        >
          {reviews.map((item, index) => (
            <div
              key={index}
              className={`px-6 testimonial-item ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <div className="px-7 py-8 lg:py-14 lg:px-10 bg-[#FBFBFB] rounded-[50px] testimonial-bg transition-all duration-100">
                <p className="text-sm md:text-base leading-6 font-medium tracking-tight testimonial-heading">
                  {item.heading}
                </p>
                <p className="text-sm md:text-base leading-6 font-normal tracking-tight mt-4 md:mt-7 testimonial-quote">
                  {item.quote}
                </p>
                <div className="avatar-info mt-4 md:mt-7 flex items-center gap-2">
                  <img src={item.avatar} alt={item.name} />
                  <div>
                    <p>
                      <span className="font-semibold text-sm testimonial-name">
                        {item.name}
                      </span>
                      ,
                    </p>
                    <p className="text-sm">{item.role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

import React from "react";
import HomeLayouts from "../../components/layouts/HomeLayouts";
import { useTranslation } from "react-i18next";
import Button from "../../components/ui/button";
import BannerImg from "../../assets/images/banner.png";

export default function AboutBannerSection() {
  const { t } = useTranslation("aboutUs");

  return (
    <div
      className="about-banner h-[705px] bg-cover bg-bottom bg-no-repeat pt-24 md:pt-12"
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className="max-w-[660px] text-center px-6">
          <h3 className="lg:text-heading3 text-[32px] leading-[38px] tracking-tighter text-black text-opacity-60">
            {t("heading_part1")}
          </h3>
          <h2 className="lg:text-heading2 mt-4 text-[44px] leading-[52px] text-primary-100">
            {t("heading_part2")}
          </h2>
          <h6 className="text-heading6 mx-auto mt-8 max-w-[252px] lg:max-w-[520px]">
            {t("description")}
          </h6>
          <a href="/onboarding">
            <Button
              variant="primary"
              className="h-12 w-40 mx-auto md:mx-0 mt-8"
            >
              {t("button_text")}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

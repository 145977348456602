import React, { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';

// Create a context with an empty default value. We'll never actually use this default value directly,
// because we'll always provide a value using the context provider below.
const userIdentifierContext = createContext({
  userIdentifier: Cookies.get('userIdentifier'), // Default value
});

// Component to provide the context value
export const UserIdentifierProvider = ({ children }) => {
  const [userIdentifier, setUserIdentifier] = useState(Cookies.get('userIdentifier'));
  
  return (
    <userIdentifierContext.Provider value={{ userIdentifier }}>
      {children}
    </userIdentifierContext.Provider>
  );
};

// Custom hook to use the context
export const useUserIdentifier = () => useContext(userIdentifierContext);

// import { Avatar } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/ui/button";

import Avatar1 from "../../assets/images/avatar1.png";
import Avatar2 from "../../assets/images/avatar2.png";
import Avatar3 from "../../assets/images/avatar3.png";
import Avatar4 from "../../assets/images/avatar4.png";
import Avatar5 from "../../assets/images/avatar5.png";
import HBRightImg from "../../assets/images/home-banner-right-content.png";
import BannerImg from "../../assets/images/banner.png";

export default function HomeBannerSection() {
  const { t } = useTranslation();

  return (
    <div
      className="home-banner h-[705px] bg-cover bg-bottom bg-no-repeat pt-24 md:pt-12"
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <div className="container flex h-full w-full flex-col items-center justify-center md:flex-row gap-6">
        <div className="flex-1">
          <div className="text-center md:text-left">
            <h2 className="lg:text-heading1 mt-4 text-[34px] leading-[40px] text-black">
              {t("banner_find_dream_job")}
            </h2>
            <h6 className="lg:text-heading6 mt-8 max-w-[252px] text-lg lg:max-w-[375px] mx-auto md:mx-0">
              {t("banner_benefits_start")}
              <span className="font-bold">
                <i>{t("banner_competitive_pay")}</i>
              </span>{" "}
              {t("banner_and")}
              <span className="font-bold text-primary-100">
                <i>{t("banner_best_conditions")}</i>
              </span>
            </h6>
            <a href="/onboarding">
              <Button
                variant="primary"
                className="h-12 w-40 mx-auto md:mx-0 mt-8"
              >
                {t("banner_get_started")}
              </Button>
            </a>
          </div>

          <div className="mt-14 hidden lg:block">
            <div className="avatar-group [&>img]:w-12 [&>img]:h-12 [&>img]:relative [&>img]:border-2 [&>img]:border-white [&>img]:rounded-full flex items-center">
              <img src={Avatar5} alt="" className="z-[5]" />
              <img src={Avatar1} alt="" className="left-[-22px] z-[4]" />
              <img src={Avatar3} alt="" className="left-[-44px] z-[3]" />
              <img src={Avatar4} alt="" className="left-[-66px] z-[2]" />
              <img src={Avatar2} alt="" className="left-[-88px] z-[1]" />
            </div>
            <p className="mt-[10px] text-xs -tracking-wider">
              {t("banner_trusted_customers")}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={HBRightImg}
            alt="Right Content"
            className="max-w-[330px] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[600px]"
          />
        </div>
      </div>
    </div>
  );
}

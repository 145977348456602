import React from "react";
import IconSuccess from "../../../assets/images/icon_success.svg";
import { useTranslation } from "react-i18next";

export default function Success() {
  const { t } = useTranslation("onboarding");

  return (
    <div className="max-w-[830px] mx-auto text-center mb-24 mt-32 md:mt-48">
      <img src={IconSuccess} alt="" className="inline-block" />
      <h2 className="text-[40px] leading-[48px] md:text-heading1 mt-12">
        {t("thankYouMessage")}
      </h2>
      <p className="text-heading6 leading-[24px] mt-12">
        {t("matchingMessage")}
      </p>
      <p className="mt-4 text-primary-100 text-heading6 leading-[24px]">
        {t("excitedMessage")}
      </p>
    </div>
  );
}

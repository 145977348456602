import { signUp, logOut, signIn, recoverPassword, resetPassword, userUpdate } from '../api/users';

export default class Auth {
  constructor(user) {
    this.currentUser = user;
  }

  getCurrentUser = () => {
    return this.currentUser;
  };

  userSignUp = (email, password, params, onSuccess, onError) => {
    signUp(email, password, params, (result) => {
      this.currentUser = result;
      if (onSuccess) {
        onSuccess(result);
      }
    }, (errors) => {
      if (onError) {
        onError(errors);
      }
    });
  }

  isLoggedIn = () => {
    var currentUserPresent = Object.keys(this.currentUser).length !== 0;

    if (!currentUserPresent) {
      return false;
    }

    return true;
  }

  userLogOut = () => {
    logOut(() => {
      this.currentUser = {};
      window.location = '/';
    });
  }

  userSignIn = (email, password, onSuccess, onError) => {
    signIn(email, password, (user) => {
      this.currentUser = user;
      onSuccess(user);
    }, onError);
  }

  userRecoverPassword = (email, onSuccess, onError) => {
    recoverPassword(email, onSuccess, onError);
  }

  userResetPassword = (resetPasswordToken, password, onSuccess, onError) => {
    resetPassword(resetPasswordToken, password, (user) => {
      this.currentUser = user;
      onSuccess();
    }, onError);
  }

  userUpdate = (updates, onSuccess, onError) => {
    userUpdate(updates, (user) => {
      this.currentUser = user;
      if (onSuccess) {
        onSuccess(user);
      }
    }, onError);
  }
}

import React, { useState, useEffect } from "react";
import { useStep } from "../../../context/onboardingStepContext";

import Logo from "../../../assets/images/Logo.png";
import LogoSecondary from "../../../assets/images/Logo_secondary.png";
import Flag_GB from "../../../assets/images/flag_gb.svg";
import Flag_DE from "../../../assets/images/flag_DE.svg";
import { useLanguage } from "../../../context/LanguageContext";

export default function Header({}) {
  const [scrolling, setScrolling] = useState(false);
  const { language, setLanguage } = useLanguage(); 

  const { step } = useStep();

  const flags = {
    EN: Flag_GB,
    DE: Flag_DE,
  };

  const handleSetLanguage = (e, lang) => {
    e.preventDefault();
    if (lang === language) return;
    setLanguage(lang); // Update context language
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <header className="fixed left-0 top-0 w-full z-30">
      <nav className={`relative z-30 w-full `}>
        <section
          className={`mx-auto flex h-14 w-full items-center justify-between px-5 md:mx-auto md:h-20 md:px-10  lg:h-[108px] transition-all duration-300 ${
            scrolling ? "drop-shadow-md bg-white lg:h-[90px]" : ""
          }`}
        >
          <div className="flex items-center gap-1 text-center md:gap-8 lg:gap-12">
            <a href="/">
              {!scrolling && step !== 5 ? (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={LogoSecondary}
                />
              ) : (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={Logo}
                />
              )}
            </a>
          </div>
          <div className="flex h-10 items-center gap-1 md:gap-5">
            <div className="dropdown mr-5">
              <button
                className="flex cursor-pointer items-center gap-2"
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                type="button"
              >
                <span
                  className={`hidden text-xs font-normal text-black md:text-sm lg:block`}
                >
                  {language}
                </span>
                <img src={flags[language]} alt="en" className="h-5 w-7" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className={`md:text-black ${
                    scrolling || step === 5 ? "text-black" : "text-white"
                  }`}
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>

              <div id="dropdown" className="hidden">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200 bg-white rounded-md p-2 flex flex-col shadow-lg"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li className={`p-2 ${language === "EN" ? "selected" : ""}`}>
                    <a href="#" onClick={(e) => handleSetLanguage(e, "EN")}>
                      <span className="flex items-center gap-2 text-black hover:text-primary-100">
                        <span className="hidden text-xs font-normal  md:text-sm lg:block">
                          EN
                        </span>
                        <img src={Flag_GB} alt="en" className="h-5 w-7" />
                      </span>
                    </a>
                  </li>
                  <li className={`p-2 ${language === "DE" ? "selected" : ""}`}>
                    <a href="#" onClick={(e) => handleSetLanguage(e, "DE")}>
                      <span className="flex items-center gap-2 text-black hover:text-primary-100">
                        <span className="hidden text-xs font-normal md:text-sm lg:block">
                          DE
                        </span>
                        <img src={Flag_DE} alt="en" className="h-5 w-7" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </nav>
    </header>
  );
}

import React from "react";

import HomeLayouts from "../../components/layouts/HomeLayouts";
import JobBannerSection from "./JobBannerSection";
import JobListing from "./JobListing";
import Industries from "./Industries";
import { useTranslation } from "react-i18next";

export default function Jobs() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <HomeLayouts isPrimary={false}>
      <JobBannerSection />
      <JobListing />
      {currentLanguage === "de" && <Industries />}
    </HomeLayouts>
  );
}

import React from "react";

import Label from "../../components/ui/label";

import IndustryImg from "../../assets/images/Images.png";
import { useTranslation } from "react-i18next";

export default function IndustrySection() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col md:flex-row items-center container mt-8 md:mt-16 xl:mt-24 pt-20 gap-14 lg:gap-20 lg:px-24">
        <div className="flex-1">
          <div className="max-w-[416px]">
            <Label
              labelText={t("industries")}
              className={"ml-auto mr-auto md:mr-auto md:ml-0"}
            />
            <h4 className="text-heading4 md:text-heading3 text-black my-10 text-center md:text-left">
              {t("connecting_talent_title")}
            </h4>
            <p className="text-paragraph text-center md:text-left">
              {t("we_have_helped")} {t("thousands_of_skilled_workers")}{" "}
              <span className="font-bold">{t("find_a_new_career")}</span>{" "}
              {t("with_hundreds_of_employers")} {t("in_these_industries")}.
            </p>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={IndustryImg}
            alt="Industry Image"
            className="mx-auto ml-auto"
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import JobItem from "./JobItem";
import { useTranslation } from "react-i18next";

export default function JobListing() {
  const { t } = useTranslation('jobs');

  const jobs = t('jobs', { returnObjects: true });

  return (
    <div className="container mt-14 md:mt-24 md:px-24 mb-40">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-[940px] mx-auto">
        {jobs.map((jobDetail, index) => (
          <JobItem jobDetail={jobDetail} key={index} />
        ))}
      </div>
    </div>
  );
}

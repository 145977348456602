import React from "react";

import useIsMobile from "../../utils/hooks/useIsMobile";
import { useStep } from "../../context/onboardingStepContext";

import OnboardInfoImg from "../../assets/images/onboard_info2.png";
import OnboardInfoMobileImg from "../../assets/images/onboard_info_mobile2.png";
import OnboardInfoDeImg from "../../assets/images/onboard_info2.png";
import OnboardInfoMobileDeImg from "../../assets/images/onboard_info_mobile2.png";

import EducationImg from "../../assets/images/onboard_education.png";
import EducationMobileImg from "../../assets/images/onboard_education_mobile.png";
import HistoryImg from "../../assets/images/onboard_history.png";
import HistoryMobileImg from "../../assets/images/onboard_history_mobile.png";
import IndustryImg from "../../assets/images/onboard_industry.png";
import IndustryMobileImg from "../../assets/images/onboard_industry_mobile.png";

import IconWallet from "../../assets/images/icon_wallet.svg";
import IconJob from "../../assets/images/icon_job.svg";
import IconBuilding from "../../assets/images/icon_building.svg";
import { useTranslation } from "react-i18next";

export default function OnboardingLeftContent() {
  const { step } = useStep();
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation("onboarding");
  const currentLanguage = i18n.language;

  const bgImages = [
    {
      desktop: currentLanguage === "en" ? OnboardInfoImg : OnboardInfoDeImg,
      mobile:
        currentLanguage === "en"
          ? OnboardInfoMobileImg
          : OnboardInfoMobileDeImg,
    },
    {
      desktop: IndustryImg,
      mobile: IndustryMobileImg,
    },
    {
      desktop: HistoryImg,
      mobile: HistoryMobileImg,
    },
    {
      desktop: EducationImg,
      mobile: EducationMobileImg,
    },
    {
      desktop: OnboardInfoImg,
      mobile: OnboardInfoMobileImg,
    },
  ];

  if (isMobile) {
    return <div className=""></div>
  }

  if (step === 6) {
    return (<></>);
  }

  return (
    <div
      className={`bg-cover bg-bottom bg-no-repeat px-12 md:px-24 flex flex-col justify-end py-16 md:py-12 ${
        step !== 0 && step !== 4 ? "h-[460px]" : ""
      } md:h-[880px]`}
      style={{
        backgroundImage: `url(${
          isMobile ? bgImages[step].mobile : bgImages[step].desktop
        })`,
      }}
    >
      <div className={`${step !== 0 && step !== 4 ? "hidden" : ""}`}>
        <h2 className="text-center  text-heading4 md:text-[40px] md:leading-[48px] tracking-tighter text-white mb-[200px]">
          {t("getStartedTitle")}
        </h2>
        <div className="grid grid-cols-1 gap-7 max-w-[200px] md:max-w-[280px] text-white mt-12">
          <div>
            <img src={IconWallet} alt="" />
            <p className="text-paragraph mt-7">{t("walletText")}</p>
          </div>
          <div>
            <img src={IconJob} alt="" />
            <p className="text-paragraph mt-7">{t("jobOffersText")}</p>
          </div>
          <div>
            <img src={IconBuilding} alt="" />
            <p className="text-paragraph mt-7">{t("employersText")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

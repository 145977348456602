import React from "react";
import Label from "../../components/ui/label";

import Benefit1 from "../../assets/images/benefit1.png";
import Benefit2 from "../../assets/images/benefit2.png";
import Benefit3 from "../../assets/images/benefit3.png";
import Benefit4 from "../../assets/images/benefit4.png";
import customBgThree from "../../assets/images/bg-4.png";
import { useTranslation } from "react-i18next";

export default function WhyChooseSection() {
  const { t } = useTranslation();

  const benefits = [
    {
      icon: Benefit1,
      title: t("competitive_compensation_title"),
      description: t("competitive_compensation_description"),
    },
    {
      icon: Benefit2,
      title: t("new_positions_title"),
      description: t("new_positions_description"),
    },
    {
      icon: Benefit3,
      title: t("easy_application_title"),
      description: t("easy_application_description"),
    },
    {
      icon: Benefit4,
      title: t("jobs_that_fit_title"),
      description: t("jobs_that_fit_description"),
    },
  ];

  return (
    <div className="relative">
      <div className="container mt-8 md:mt-16 xl:mt-24 pt-20 relative z-10 lg:px-24">
        <div className="">
          <Label labelText={t("why_us")} className={"mx-auto"} />

          <h4 className="text-heading4 md:text-heading3 text-black mt-6 text-center">
            {t("why_choose_wandel")}
          </h4>

          <div className="mt-14">
            <div className="grid md:grid-cols-2 gap-[30px] md:gap-14 lg:gap-20">
              {benefits.map((item, index) => (
                <div
                  className="rounded-[30px] bg-[#F8F8F8] bg-opacity-60 py-5 px-[30px] md:py-10 md:px-[50px]"
                  key={index}
                >
                  <img src={item.icon} alt="Benefit" className="w-12" />
                  <h5 className="text-lg tracking-tight font-medium md:text-[26px] leading-[30px] my-[18px]">
                    {item.title}
                  </h5>
                  <p
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute bg-no-repeat w-full h-full top-0 bg-right-top bg-20 md:bg-auto z-[1px]"
        style={{ backgroundImage: `url(${customBgThree})` }}
      ></div>
    </div>
  );
}

import React from "react";
import Header from "../partials/header";
import Footer from "../partials/footer";

export default function HomeLayouts({ isPrimary = true, children }) {
  return (
    <>
      <Header isPrimary={isPrimary} />
      <main>{children}</main>
      <Footer />
    </>
  );
}

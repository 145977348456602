import React from "react";
import Logo from "../../../assets/images/Logo.png";
import IconFacebook from "../../../assets/images/social-facebook.png";
import IconLinkedin from "../../../assets/images/social-linkedin.png";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation("onboarding");

  return (
    <footer>
      <div className="footer-menu-section bg-[#FAFAFA] rounded-tl-[60px] rounded-tr-[60px]">
        <div className="container lg:px-24 py-14 md:py-24">
          <div className="flex flex-col md:flex-row items-center justify-between gap-y-16">
            <div className="footer-logo">
              <img
                src={Logo}
                alt="Footer Logo"
                className="w-[134px] ml-auto mr-auto md:ml-0 md:mr-auto"
              />
              <p className="text-xs mt-3">
                {t("allRightsReserved")}
              </p>
            </div>
            <div className="footer-menu">
              <ul className="flex flex-col md:flex-row gap-10 md:gap-4 lg:gap-12">
                <li>
                  <a href="/about-us">
                    <span className="text-base text-black hover:text-primary-100">
                      {t("aboutUs")}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="text-base text-black hover:text-primary-100">
                      {t("privacyPolicy")}
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="text-base text-black hover:text-primary-100">
                      {t("impressum")}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-follow">
              <div className="flex items-center gap-4">
                <p className="text-base">{t("followUs")}</p>
                <a
                  href="#"
                  className="w-10 h-10 flex items-center justify-center bg-[#EEEEFF] rounded-full"
                >
                  <img src={IconFacebook} alt="Facebook" />
                </a>
                <a
                  href="#"
                  className="w-10 h-10 flex items-center justify-center bg-[#EEEEFF] rounded-full"
                >
                  <img src={IconLinkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";

export default function Accordion({ question, answer, index }) {
  return (
    <div className="accordion-item p-5 bg-[#FBFBFB] rounded-[20px]">
      <input type="checkbox" id={`accordion${index}`} className="hidden" />
      <label htmlFor={`accordion${index}`}>
        <span className="text-2xl tracking-tighter flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#5252FF"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
              clipRule="evenodd"
            />
          </svg>
          {question}
        </span>
      </label>
      <div className="accordion-box transition-all duration-200 h-auto overflow-hidden">
        <p className="text-lg mt-3 pl-8">{answer}</p>
      </div>
    </div>
  );
}

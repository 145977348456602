import React from "react";

import HomeLayouts from "../../components/layouts/HomeLayouts";
import Sponsors from "../../components/partials/sponsors";
import AboutBannerSection from "./AboutBannerSection";
import FAQs from "./FAQs";
import HowWorksSection from "./HowWorksSection";
import OurCommitmentSection from "./OurCommitmentSection";
import OurMissionSection from "./OurMissionSection";
import WhyChooseSection from "./WhyChooseSection";

import customBgOne from "../../assets/images/bg-1.png";
import customBgTwo from "../../assets/images/bg-2.png";

export default function AboutUS() {
  return (
    <HomeLayouts>
      <AboutBannerSection />

      <div className="relative z-10">
        <Sponsors />
        <OurMissionSection />
        <HowWorksSection />
        <OurCommitmentSection />
        <WhyChooseSection />
        <FAQs />
        <div
          className="custom-bg-2 bg-customBgOne bg-no-repeat bg-auto absolute top-0 left-0 w-full h-full bg-left-50 md:bg-left-450 z-5"
          style={{ backgroundImage: `url(${customBgOne})` }}
        ></div>
        <div
          className="custom-bg-1 bg-customBgTwo bg-no-repeat bg-auto absolute top-0 left-0 w-full h-full bg-right-250-100 sm:bg-right-100 z-5"
          style={{ backgroundImage: `url(${customBgTwo})` }}
        ></div>
      </div>
    </HomeLayouts>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function Steps({ active, step, className }) {
  const { t } = useTranslation();

  return (
    <div
      className={`step-item shadow-lg rounded-2xl md:rounded-[20px] w-[60px] h-[60px] md:w-20 md:h-20 transform rotate-[-19deg] ${
        active ? "bg-activeStep" : "bg-[#c3c3c3]"
      } ${className}`}
    >
      <div className="flex flex-col items-center justify-center transform rotate-[19deg] translate-y-2">
        <p className="text-sm md:text-base text-white text-opacity-90 font-medium">
          {t('step')}
        </p>
        <p className="text-xl text-[28px] text-white text-opacity-90 md:leading-10 font-semibold tracking-tight">
          {step}
        </p>
      </div>
    </div>
  );
}

import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Context from "../../utils/context";

export default function SignIn({}) {
  const context = useContext(Context);
  let history = useHistory();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState();

  const location = useLocation();
  let returnTo = "";
  let searchParams = "";

  if (history.location.state) {
    searchParams = new URLSearchParams(history.location.state);
    returnTo = history.location.state;
    if (searchParams.get("return_to")) {
      returnTo = searchParams.get("return_to");
    }
  } else {
    searchParams = new URLSearchParams(location.search);
    if (searchParams.get("return_to")) {
      returnTo = searchParams.get("return_to");
    }
  }

  console.log("returnTo", returnTo);

  const onClick = (e) => {
    e.preventDefault();

    context.auth.userSignIn(
      email,
      password,
      (data) => {
        // TODO figure out why the return to isn't working
        // if (returnTo) {
        //   history.push(returnTo);
        // }
        // else {
        //   history.push('/dashboard')
        // }
        history.push("/dashboard");
      },
      (errors) => {
        setError(errors.join(","));
      }
    );
  };

  return (
    <div className={`${styles.form_signin} w-100 m-auto`}>
      <form>
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

        <div class="form-floating">
          <input
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            class="form-control"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating">
          <input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
          />
          <label for="floatingPassword">Password</label>
        </div>

        <button
          onClick={onClick}
          class="btn btn-primary w-100 py-2"
          type="submit"
        >
          Sign in
        </button>
      </form>
    </div>
  );
}

import React from "react";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useTranslation } from "react-i18next";

import BannerImg from "../../assets/images/job-banner-img.png";
import BannerMobileImg from "../../assets/images/job-banner-mobile-img.png";

export default function JobBannerSection() {
  const isMobile = useIsMobile();
  const { t } = useTranslation("jobs");

  return (
    <div
      className="bg-jobBannerMobile md:bg-jobBanner h-[600px] md:h-[705px] bg-cover bg-bottom bg-no-repeat pt-12 px-10 flex items-center justify-center"
      style={{
        backgroundImage: `url(${!isMobile ? BannerImg : BannerMobileImg})`,
      }}
    >
      <div className="max-w-[660px] mx-auto text-center">
        <h1 className="text-[44px] leading-[55px] md:text-heading1 text-white">
          {t("title")} <span className="text-white text-opacity-60">{t("subtitle")}</span>
        </h1>
        <p className="text-base md:text-heading6 mt-8 text-white">
          {t("description")}
        </p>
      </div>
    </div>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import homeEN from "./locales/en/home.json";
import homeDE from "./locales/de/home.json";
import aboutUsEN from "./locales/en/aboutUs.json";
import aboutUsDE from "./locales/de/aboutUs.json";
import onboardingEN from "./locales/en/onboarding.json";
import onboardingDE from "./locales/de/onboarding.json";
import jobsEN from "./locales/en/jobs.json";
import jobsDE from "./locales/de/jobs.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      home: homeEN,
      aboutUs: aboutUsEN,
      onboarding: onboardingEN,
      jobs:jobsEN
    },
    de: {
      home: homeDE,
      aboutUs: aboutUsDE,
      onboarding: onboardingDE,
      jobs:jobsDE
    },
  },
  lng: "de", // default language
  fallbackLng: "de",
  ns: ["home", "aboutUs","onboarding","jobs"], 
  defaultNS: "home",  // default namespace
  interpolation: {
    escapeValue: false,
  },
});

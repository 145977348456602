import React from "react";

import Label from "../../components/ui/label";

import LeftContent from "../../assets/images/left-content.png";
import IconShare from "../../assets/images/icon_share.png";
import IconExchange from "../../assets/images/icon_exchange.png";
import IconCV from "../../assets/images/icon_cv.png";
import Steps from "../../components/ui/steps";
import { useTranslation } from "react-i18next";

export default function HowWorksSection() {
  const { t } = useTranslation();

  const steps = [
    {
      icon: IconShare,
      title: t("share_skills_title"),
      description: t("share_skills_description"),
      step: 1,
      active: true,
    },
    {
      icon: IconExchange,
      title: t("get_matched_title"),
      description: t("get_matched_description"),
      step: 2,
    },
    {
      icon: IconCV,
      title: t('apply_with_confidence_title'),
      description:t('apply_with_confidence_description'),
      step: 3,
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 container mt-8 md:mt-16 xl:mt-24 pt-20 gap-10 lg:px-24">
      <div>
        <div className="max-w-[440px] ml-auto mr-auto md:ml-0">
          <img src={LeftContent} alt="How it works" />
        </div>
      </div>
      <div>
        <Label labelText={t('how_it_works')} />
        <div className="steps-container mt-8 md:mt-10">
          {steps.map((step, index) => (
            <div
              className="flex items-start gap-4 py-9 [&:not(:last-child)]:border-b border-black border-opacity-10"
              key={index}
            >
              <img src={step.icon} alt="" className="w-6 md:w-[30px]" />
              <div className="step-content flex-1 text-left">
                <h5 className="text-heading5">{step.title}</h5>
                <p className="text-sm mt-2 max-w-[300px]">{step.description}</p>
              </div>
              <div className="w-[76px] md:w-[100px]">
                <Steps step={step.step} active={step.active} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

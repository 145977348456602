import React, { useState, useEffect, forwardRef } from "react";
import AWS from "aws-sdk";
import uuid from "react-uuid";

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3FileInput = forwardRef(
  (
    {
      onChange = (allFiles) => console.log(allFiles),
      bucketName = gon.s3_bucket_name,
      dirName = gon.s3_store_dir,
      name,
      accept,
      multiple = false,
      values = null,
      className,
      setIsLoading,
    },
    ref
  ) => {
    const allFiles = [];
    const [loading, setLoading] = useState(false);
    const [filesProgress, setFilesProgress] = useState([]);

    let defaultLabelText = `Choose file (${accept})`;
    if (values && values.filter((v) => v).length > 0) {
      defaultLabelText = values.map((v) => v.split("/").pop()).join(",");
    }
    const [labelText, setLabelText] = useState(defaultLabelText);

    useEffect(() => {
      setLabelText(defaultLabelText);
    }, [values]);

    const config = {
      region: gon.s3_region,
      accessKeyId: gon.s3_key,
      secretAccessKey: gon.s3_secret,
      s3Url: "https://mosaicpuzzlesadmin.s3.amazonaws.com",
    };

    const s3 = new AWS.S3(config);
    const s3UploadFile = (files, i) => {
      setIsLoading(true);
      setLoading(true);
      if (files.length === i) {
        setLoading(false);
      }
      const file = files[i];

      if (!file) {
        let fileNames = [];
        for (var a = 0; a < files.length; a++) {
          fileNames.push(files[a].name);
        }
        setLabelText(fileNames.join(","));

        if (multiple) {
          onChange(allFiles);
        } else {
          onChange(allFiles[0]);
        }

        return;
      }
      const fileUploadProgress = {
        fileName: file.name,
        progress: 0,
      };
      setFilesProgress((prev) => [...prev, fileUploadProgress]);

      const updateProgress = (percent) => {
        setFilesProgress((prev) =>
          prev.map((fp) =>
            fp.fileName === file.name ? { ...fp, progress: percent } : fp
          )
        );
      };

      const params = {
        Bucket: `${bucketName}/${dirName}`,
        Key: `${uuid()}/${file.name}`,
        Body: file,
        ContentType: file.type,
      };

      const upload = s3.upload(params);

      upload.on("httpUploadProgress", (event) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        updateProgress(progress);
      });

      upload.send((err, data) => {
        if (err) {
          setLoading(false);
          console.error("Upload Error:", err);
        } else {
          setLoading(false);
          updateProgress(100);
          allFiles.push(data.Location);
          s3UploadFile(files, i + 1);
          setIsLoading(false);
        }
      });
    };

    const onFileChange = (e) => {
      setFilesProgress([]);
      s3UploadFile(e.target.files, 0);
    };

    return (
      <>
        <div className={`${className}`}>
          <div className="w-100">
            <div className="custom-file">
              <input
                className="custom-file-input"
                multiple={multiple}
                onChange={onFileChange}
                type="file"
                name={name}
                accept={accept}
                ref={ref}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {labelText}
              </label>
            </div>
          </div>
          {loading && (
            <div className="d-flex justify-content-end w-25">
              {filesProgress.map((fileProgress, index) => (
                <span key={index}>{fileProgress.progress}% Completed</span>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default S3FileInput;

import React from "react";
import { useTranslation } from "react-i18next";

import Label from "../../components/ui/label";
import StatisticImg from "../../assets/images/statistic_img2.png";
import StatisticImgDE from "../../assets/images/statistic_img_DE2.png";

import Button from "../../components/ui/button";

export default function StatisticSection() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="flex flex-col-reverse md:flex-row items-center container mt-8 md:mt-16 xl:mt-24 pt-20 gap-14 lg:gap-20 lg:px-24 pb-40">
      <div className="flex-1">
        <img
          src={currentLanguage === "en" ? StatisticImg : StatisticImgDE}
          alt={t("statistic_image_alt")}
          className="mx-auto ml-auto"
        />
      </div>
      <div className="flex-1">
        <div className="max-w-[300px] md:max-w-[420px]">
          <Label
            labelText={t("statistic_label")}
            className={"ml-auto mr-auto md:mr-auto md:ml-0"}
          />
          <h4 className="text-heading4 md:text-heading3 text-black mt-6 text-center md:text-left">
            {t("achieve_more_with_wandel")}
          </h4>
          <h5 className="text-lg font-medium md:text-[28px] mt-6 text-center md:text-left">
            {t("see_earning_potential")}
          </h5>
          <p className="text-base md:text-paragraph text-center md:text-left mt-6">
            {t("start_exploring")}
          </p>

          <div className="btn-group mt-10 md:mt-12 flex flex-col md:flex-row items-center gap-5">
            <a href="/onboarding">
              <Button variant="primary" className="h-12 w-40">
                {t("create_profile")}
              </Button>
            </a>
            {/* <Button variant="outline" className="h-12 w-40">
              {t("learn_more")}
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import * as Yup from "yup";

export const stringSchema = () => {
  return Yup.string().required("Required");
};

export const emailSchema = () => {
  return Yup.string()
    .email("Invalid Email Format")
    .max(100)
    .required("Required");
};
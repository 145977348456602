import React from "react";

import HomeLayouts from "../../components/layouts/HomeLayouts";
import HomeBannerSection from "./HomeBannerSection";
import HowWorksSection from "./HowWorksSection";
import IndustrySection from "./IndustrySection";
import WhoWeAreSection from "./WhoWeAreSection";
import StatisticSection from "./StatisticSection";
import Testimonials from "./Testimonials";
import WhyChooseSection from "./WhyChooseSection";
import Sponsors from "../../components/partials/sponsors";

import customBgOne from "../../assets/images/bg-1.png";
import customBgTwo from "../../assets/images/bg-2.png";

export default function Home() {
  return (
    <HomeLayouts>
      <HomeBannerSection />
      <div className="relative z-10">
        <div className="z-10 relative">
          <Sponsors />
          <WhoWeAreSection />
          <WhyChooseSection />
          <IndustrySection />
          <HowWorksSection />
          <Testimonials />
          <StatisticSection />
        </div>
        <div
          className="custom-bg-2 bg-no-repeat bg-auto absolute top-0 left-0 w-full h-full bg-left-50 md:bg-left-top z-1"
          style={{ backgroundImage: `url(${customBgOne})` }}
        ></div>
        <div
          className="custom-bg-1 bg-no-repeat bg-auto absolute top-0 left-0 w-full h-full bg-right-250-100 sm:bg-right-100 z-1"
          style={{ backgroundImage: `url(${customBgTwo})` }}
        ></div>
      </div>
    </HomeLayouts>
  );
}

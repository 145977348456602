import React, { useContext } from "react";
import Context from "../../utils/context";

export default function Dashboard({}) {
  const context = useContext(Context);

  const signOut = () => {
    context.auth.userLogOut();
  };

  return (
    <>
      <div>Hello {context.auth.getCurrentUser().email}!</div>
      <div>
        <button onClick={signOut} class="btn btn-primary w-100 py-2">
          Sign out
        </button>
      </div>
    </>
  );
}

import React from "react";

const Button = ({
  variant = "primary", // default type
  size = "md", // optional size prop
  children,
  onClick,
  disabled = false,
  className,
  href,
  type,
  ...rest
}) => {
  // Define base styles that apply to all buttons
  const baseStyles =
    "font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all h-12 inline-flex items-center justify-center";

  // Define styles for each variant type
  const variantStyles = {
    primary:
      "border-primary-100 bg-primary-100  hover:bg-blue-800 text-white text-[15px] leading-[18px]",
    outline:
      "border-[#3C3C434A] bg-white border hover:!bg-gray-100 text-black hover:text-black",
    text: "text-blue-600 hover:text-blue-700 focus:ring-blue-500",
    link: "text-black hover:text-primary-100",
  };

  // Define styles for each size
  const sizeStyles = {
    sm: "px-2 py-1 text-sm",
    md: "px-4 py-2",
    lg: "px-6 py-3 text-lg",
  };

  // Combine all styles
  const buttonStyles = `${baseStyles} ${variantStyles[variant]} ${
    sizeStyles[size]
  } ${disabled ? "opacity-50 cursor-not-allowed" : ""} ${className}`;

  if (variant === "link" && href) {
    return (
      <a
        href={href}
        className={buttonStyles}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
      >
        {children}
      </a>
    );
  }

  if (type === "file") {
    return (
      <label
        className={buttonStyles}
        disabled={disabled}
        htmlFor="upload"
        {...rest}
      >
        <input type="file" name="" id="upload" className="hidden" />
        {children}
      </label>
    );
  }

  return (
    <button
      className={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;

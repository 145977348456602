import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Validator from "../../../utils/validation";
import { useStep } from "../../../context/onboardingStepContext";
import { useUserIdentifier } from "../../../context/userIdentifierContext";
import axios from "axios";
import Cookies from 'js-cookie';

import Button from "../../../components/ui/button";
import Label from "../../../components/ui/label";
import IconFree from "../../../assets/images/icon_free.svg";
import { useTranslation } from "react-i18next";
import Flag_DE from "../../../assets/images/flag_DE.svg";

const validationSchema = Yup.object().shape({
  full_name: Validator.stringSchema(),
  email: Validator.emailSchema().notRequired(),
  phone: Validator.stringSchema(),
});

export default function ContactInformation() {
  const { setStep } = useStep();
  const { userIdentifier } = useUserIdentifier();
  const { t } = useTranslation("onboarding");

  const forms = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      full_name: "",
      email: "",
      phone: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = forms;

  const onSubmit = (data) => {
    axios.post("/api/v1/notifications", {
      message: `User \`${Cookies.get('userIdentifier')}\` submitted information on \`Contact Information\`.`,
      info: data,
    });

    axios.post("/welcome/lead_event", {
      lead: {
        email: data.email,
        first_name: data.full_name,
        phone_number: data.phone,
      },
    });

    setStep(5);
  };

  return (
    <div className="mt-10 max-w-[460px]">
      <Label labelText={t("contactInformationLabel")} />
      <h3 className="text-[30px] leading-[36px] font-medium mt-8 max-w-[380px]">
        {t("contactInformationTitle")}
      </h3>
      {/* <p className="text-heading6 leading-[24px] mt-8 backdrop-blur-md">
        {t("contactInformationDescription")}
      </p> */}

      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-y-6">
          <div>
            <label
              htmlFor="full_name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("fullNameLabel")}
            </label>
            <input
              type="text"
              id="full_name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              {...register("full_name")}
            />
            <span className="text-red-700 mt-1 text-xs">
              {errors.full_name?.message}
            </span>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("emailLabel")}
            </label>
            <input
              type="text"
              id="email"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              {...register("email")}
            />

            {/* <span className="text-red-700 mt-1 text-xs">
              {errors.email?.message}
            </span> */}
          </div>
          <div className="relative">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("phoneLabel")}
            </label>
            <input
              type="number"
              id="phone"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10"
              required
              {...register("phone")}
            />

            <span className="text-red-700 mt-1 text-xs">
              {errors.phone?.message}
            </span>
          </div>

          <div className="flex items-start">
            <input
              id="link-checkbox"
              type="checkbox"
              value=""
              className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label
              htmlFor="link-checkbox"
              className="ms-2 text-base font-medium text-[#111111]"
            >
              {t("consentText")}

              <a
                href="https://www.wandel.com/privacy-policy"
                target="_blank"
                className="text-[#0061FF]"
              >
                {t("datenschutz")}
              </a>

              {t("von_Wandel_zu")}
            </label>
          </div>

          <div>
            <Button
              variant="primary"
              className={"w-full"}
              type={"submit"}
              disabled={!isDirty || !isValid}
            >
              {t("Submit")}
            </Button>

            {/* <p className="mt-4 text-[#111111] font-medium">
              {t("alreadyHaveAccount")}{" "}
              <a href="#" className="text-primary-100">
                {t("loginLink")}
              </a>
            </p> */}
          </div>

          <div className="flex items-center gap-7 mt-3">
            <img src={IconFree} alt="" />
            <p>
              <span className="text-primary-100 font-semibold">
                {t("freeServiceTextBold")}
              </span>{" "}
              {t("freeServiceText")}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

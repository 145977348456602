import React from "react";
import Label from "../../components/ui/label";
import Benefit5 from "../../assets/images/benefit5.png";
import Benefit6 from "../../assets/images/benefit6.png";
import Benefit7 from "../../assets/images/benefit7.png";
import Benefit8 from "../../assets/images/benefit8.png";
import { useTranslation } from "react-i18next";

export default function WhyChooseSection() {
  const { t } = useTranslation("aboutUs");

  const benefits = [
    {
      icon: Benefit5,
      title: t("why_choose.benefits.0.title"),
      description: t("why_choose.benefits.0.description"),
    },
    {
      icon: Benefit6,
      title: t("why_choose.benefits.1.title"),
      description: t("why_choose.benefits.1.description"),
    },
    {
      icon: Benefit7,
      title: t("why_choose.benefits.2.title"),
      description: t("why_choose.benefits.2.description"),
    },
    {
      icon: Benefit8,
      title: t("why_choose.benefits.3.title"),
      description: t("why_choose.benefits.3.description"),
    },
  ];
  return (
    <div className="relative">
      <div className="container mt-8 md:mt-16 xl:mt-24 pt-20 relative z-10 lg:px-24">
        <div>
          <Label labelText={t("why_choose.why_us")} className="mx-auto" />

          <h4 className="text-heading4 md:text-heading3 text-black mt-6 text-center">
            {t("why_choose.title")}
          </h4>

          <div className="mt-14">
            <div className="grid md:grid-cols-2 gap-[30px] md:gap-14 lg:gap-20">
              {benefits.map((item, index) => (
                <div
                  className="rounded-[30px] bg-[#F8F8F8] bg-opacity-60 py-5 px-[30px] md:py-10 md:px-[50px]"
                  key={index}
                >
                  <img src={item.icon} alt="Benefit" className="w-12" />
                  <h5 className="text-lg tracking-tight font-medium md:text-[26px] leading-[30px] my-[18px]">
                    {item.title}
                  </h5>
                  <p
                    className="text-sm md:text-lg"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import Label from "../../components/ui/label";
import OurMissionImg from "../../assets/images/img_our_mission2.png";
import OurMissionImgDE from "../../assets/images/img_our_missionDE2.png";

export default function OurMissionSection() {
  const { t, i18n } = useTranslation("aboutUs");
  const currentLanguage = i18n.language;

  return (
    <div>
      <div className="flex flex-col md:flex-row items-center container mt-24 gap-14 lg:gap-20 lg:px-24">
        <div className="flex-1">
          <div className="max-w-[530px]">
            <Label
              labelText={t("mission.title")}
              className={"ml-auto mr-auto md:mr-auto md:ml-0"}
            />
            <h4 className="text-heading4 md:text-heading3 text-black my-10 text-center md:text-left">
              {t("mission.heading")}
            </h4>
            <p className="text-paragraph text-center md:text-left">
              {t("mission.description")}
            </p>
          </div>
        </div>
        <div className="flex-1">
          <img
            src={currentLanguage === "en" ? OurMissionImg : OurMissionImgDE}
            alt={t("mission.title")}
            className="mx-auto ml-auto"
          />
        </div>
      </div>
    </div>
  );
}

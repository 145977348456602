import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Context from "./utils/context";
import Auth from "./utils/auth";
import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import Jobs from "./pages/jobs";
import Dashboard from "./pages/dashboard";
import SignIn from "./pages/sign_in";
import Onboarding from "./pages/onboarding";
import Join from "./components/join"

import useUserTracking from "./hooks/useUserTracking";
import { UserIdentifierProvider } from './context/userIdentifierContext';

import "./App.css";

const auth = new Auth(gon.current_user || {});

const PublicOnlyRoute = ({ component: Component, auth, path }) => {
  let redirectUrl = "/";

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        auth.isLoggedIn() ? (
          <Redirect to={{ pathname: redirectUrl }} />
        ) : (
          <Component path={path} {...props} />
        )
      }
    />
  );
};

const AuthenticatedRoute = ({ component: Component, path, auth }) => {
  const returnTo = window.location.pathname;
  const pathname = `/sign_in?return_to=${encodeURIComponent(returnTo)}`;

  if (auth.isLoggedIn()) {
    return <Route path={path} render={(props) => <Component {...props} />} />;
  }

  return <Route path={path} render={(props) => <Redirect to={pathname} />} />;
};

function App() {
  useUserTracking();
  
  return (
    <Context.Provider
      value={{
        auth: auth,
      }}
    >
      <UserIdentifierProvider>
        <Router>
          <div className="App">
            <Route exact path="/" component={Home} />
            <Route
              exact
              // auth={auth}
              path="/about-us"
              component={AboutUs}
            />
            <Route exact path="/jobs" component={Jobs} />
            <Route exact path="/onboarding" component={Onboarding} />

            <PublicOnlyRoute
              exact
              auth={auth}
              path="/sign_in"
              component={SignIn}
            />
            <AuthenticatedRoute
              exact
              auth={auth}
              path="/dashboard"
              component={Dashboard}
            />
            <PublicOnlyRoute exact auth={auth} path="/join" component={Join} />
          </div>
        </Router>
      </UserIdentifierProvider>
    </Context.Provider>
  );
}

export default App;

import React, { useState } from "react";
import S3FileInput from "../ui_kit/s3_file_input";

export default function Join({}) {
  const [view, setView] = useState("resume");
  const [resumeUrl, setResumeUrl] = useState();

  return (
    <>
      <div>Join</div>
      {view === "resume" && (
        <div>
          <S3FileInput
            onChange={(f) => {
              console.log("f", f);
              setView("thank-you");
            }}
            name={`resume`}
            accept={`*`}
            values={[resumeUrl]}
          />
        </div>
      )}
      {view === "thank-you" && <div>Thank you!</div>}
    </>
  );
}

import React, { createContext, useContext, useState } from "react";

// Create a context with an empty default value. We'll never actually use this default value directly,
// because we'll always provide a value using the context provider below.
const StepContext = createContext({
  step: 0, // Default value
  setStep: () => {}, // Placeholder function
});

// Component to provide the context value
export const StepProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  return (
    <StepContext.Provider value={{ step, setStep }}>
      {children}
    </StepContext.Provider>
  );
};

// Custom hook to use the context
export const useStep = () => useContext(StepContext);

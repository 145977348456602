import React from "react";

import { useTranslation } from "react-i18next";
import Label from "../../components/ui/label";

export default function WhoWeAreSection() {
  const { t } = useTranslation(); 

  return (
    <div className="pt-[50px] relative">
      <div className="who-we-are mt-20 max-w-[375px] md:max-w-[840px] px-6 mx-auto flex flex-col items-center relative z-10">
        <Label labelText={t('who_we_are')} />

        <h4 className="text-2xl md:text-heading4 text-center mt-10">
          {t('Wandel_matches_top_skilled_workers_with_the')}{" "}
          <span className="font-bold text-primary-100">{t('best_employers')}</span>.{t('next_big_career_move')}
        </h4>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

export default function Stepper({ step, setStep }) {
  const { t } = useTranslation("onboarding");

  const handleGotoStep = (dest) => {
    if (dest >= step) return;

    setStep(dest);
  };

  return (
    <div className="md:mt-[90px]">
      <div className="flex items-center max-w-[300px] md:max-w-[342px] w-full mr-auto ml-auto md:ml-0">
        <div className={`step relative flex-1 active-step`}>
          <div
            className="bg-[#c3c3c3] w-9 h-9 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center pt-1 cursor-pointer"
            onClick={() => handleGotoStep(1)}
          >
            <p className="text-[9px] text-white text-opacity-90 hidden md:block">
              {t("stepLabel")}
            </p>
            <p className="text-base font-semibold text-white text-opacity-90">
              1
            </p>
          </div>
        </div>
        <div className={`step relative flex-1 ${step > 1 ? "active-step" : ""}`}>
          <div
            className="bg-[#c3c3c3] w-9 h-9 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center pt-1 cursor-pointer"
            onClick={() => handleGotoStep(2)}
          >
            <p className="text-[9px] text-white text-opacity-90 hidden md:block">
              {t("stepLabel")}
            </p>
            <p className="text-base font-semibold text-white text-opacity-90">
              2
            </p>
          </div>
        </div>
        <div className={`step relative flex-1 ${step > 2 ? "active-step" : ""}`}>
          <div
            className="bg-[#c3c3c3] w-9 h-9 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center pt-1 cursor-pointer"
            onClick={() => handleGotoStep(3)}
          >
            <p className="text-[9px] text-white text-opacity-90 hidden md:block">
              {t("stepLabel")}
            </p>
            <p className="text-base font-semibold text-white text-opacity-90">
              3
            </p>
          </div>
        </div>
        <div className={`step relative ${step > 3 ? "active-step" : ""}`}>
          <div
            className="bg-[#c3c3c3] w-9 h-9 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center pt-1 cursor-pointer"
            onClick={() => handleGotoStep(4)}
          >
            <p className="text-[9px] text-white text-opacity-90 hidden md:block">
              {t("stepLabel")}
            </p>
            <p className="text-base font-semibold text-white text-opacity-90">
              4
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import Label from "../../components/ui/label";
import Accordion from "../../components/ui/accordion";

export default function FAQs() {
  const { t, i18n } = useTranslation('aboutUs');

  return (
    <div className="faq-container mb-20" key={i18n.language}>
      <div className="container mt-8 md:mt-16 xl:mt-24 pt-20 relative z-10 lg:px-24">
        <div>
          <Label labelText={t("faqs.section_title")} className="mx-auto" />
          <h4 className="text-heading4 md:text-heading3 text-black mt-6 text-center">
            {t("faqs.section_title")}
          </h4>
          <div className="mt-16">
            <div className="flex flex-col gap-9 max-w-[800px] mx-auto">
              {t("faqs.questions", { returnObjects: true }).map((faq, index) => (
                <Accordion
                  question={faq.question}
                  answer={faq.answer}
                  index={index}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
